import { defineStore } from 'pinia'
import { IPromotionEntity } from 'src/_shared/entities'

export const usePromotionStore = defineStore('promoCards', {
	state: () => ({
		items: [
			{
				id: 1,
				name: 'Pepsi 2+1',
				description:
					'Lorem ipsum dolor sit amet consectetur, adipisicing elit. A consectetur quo nulla ipsum, sint hic recusandae tempora. Doloremque, laborum atque.',
				shortDescription:
					'Покупайте два Pepsi в баночке, получайте один в подарок',
				image: {
					filename: 'pepsi',
					url: '/images/Picholder.png',
				},
				link: '/pepsi-promo',
				isActive: true,
			},
			{
				id: 2,
				name: 'CocaCola',
				description:
					'Lorem ipsum dolor sit amet consectetur, adipisicing elit. A consectetur quo nulla ipsum, sint hic recusandae tempora. Doloremque, laborum atque.',
				shortDescription: 'Buy two get one more',
				image: {
					filename: 'pepsi',
					url: '/images/Picholder.png',
				},
				link: '/pepsi-promo',
				isActive: true,
			},
		] as IPromotionEntity[],
	}),
	getters: {},
	actions: {
		async empty(): Promise<void> {
			this.$reset()
		},
	},

	persistedState: {
		persist: true,
	},
})
